import React, { useContext, useState, useEffect } from "react";
import { client } from "../lib/Client";

const MenuContext = React.createContext();

const MenuProvider = ({ children }) => {
    const settingQuery = [
        `*[!(_id in path('drafts.**')) && _type == "settings" ] {
        areDatesLocked,
        endDate,
        startDate
    }`,
    ];

    const [isMenuOpened, setMenuOpened] = useState(false);

    const openMenu = () => {
        setMenuOpened(true);
    };
    const closeMenu = () => {
        setMenuOpened(false);
    };
    const [areDatesLocked, setDatesLocked] = useState(true);
    const [globallyLockedDatesArray, setGloballyLockedDates] = useState([]);

    const getDatesInRange = (start, end) => {
        const date = new Date(start);
        const dates = [];

        while (date <= new Date(end)) {
            dates.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }

        return dates;
    };

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const settings = await client.fetch(settingQuery);
                const dates = settings.flatMap((setting) =>
                    getDatesInRange(setting.startDate, setting.endDate)
                );
                setDatesLocked(settings[0]?.areDatesLocked ?? true);
                setGloballyLockedDates(dates);
            } catch (err) {
                console.error("ERROR: ", err);
            }
        };

        fetchSettings();
    }, []);

    // console.log(
    //     "----context dates-----",
    //     areDatesLocked,
    //     globallyLockedDatesArray
    // );
    return (
        <MenuContext.Provider
            value={{
                isMenuOpened,
                openMenu,
                closeMenu,
                areDatesLocked,
                globallyLockedDatesArray,
            }}
        >
            {children}
        </MenuContext.Provider>
    );
};

export const useMenuContext = () => {
    return useContext(MenuContext);
};

export { MenuProvider };
