import { useState, useEffect } from "react";

import Image from "next/image";
import Link from "next/link";

import useCheckMobileScreen from "../../lib/IsMobile";

import pl from "../../public/flags/pl.png";
import en from "../../public/flags/en.png";

import useCheckIfLangIsPolish from "../../lib/IsPolish";

import { useMenuContext } from "../../context/menuContext";

import { useRouter } from "next/router";

import styles from "./LangSelector.module.scss";

const LangSelector = () => {
    const isPolish = useCheckIfLangIsPolish();
    const router = useRouter();
    const [opened, setOpened] = useState(false);
    const { closeMenu, openMenu } = useMenuContext();

    const handleClick = () => {
        if (!isMobile) {
            setOpened(!opened);
        }
        setOpened(true);
    };

    const isMobile = useCheckMobileScreen();

    useEffect(() => {
        router.events.on("routeChangeStart", (url, { shallow }) => {
            isMobile ? closeMenu() : openMenu();
            isMobile ? setOpened(true) : setOpened(false);
        });
    }, []);

    useEffect(() => {
        isMobile ? setOpened(true) : setOpened(false);
    }, []);
    return (
        <>
            <div className={styles.languages}>
                <div className={styles.current} onClick={() => handleClick()}>
                    <Image src={isPolish ? pl : en} alt='flag' height={24} />
                </div>
                {opened && (
                    <div className={styles.select}>
                        <Link href={isPolish ? "/" : "/pl/"}>
                            <div className={styles.language}>
                                <Image
                                    src={isPolish ? en : pl}
                                    alt=' flag'
                                    height={24}
                                />
                            </div>
                        </Link>
                    </div>
                )}
            </div>
        </>
    );
};

export default LangSelector;
