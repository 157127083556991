import React from "react";
import { useRouter } from "next/router";

const useCheckIfLangIsPolish = () => {
    const { asPath } = useRouter();
    if (asPath.startsWith("/pl")) {
        return true;
    } else {
        return false;
    }
};

export default useCheckIfLangIsPolish;
