import React from "react";
// import Image from "next/image";
import Link from "next/link";
import Script from "next/script";

import useCheckIfLangIsPolish from "../../lib/IsPolish";

import styles from "./Footer.module.scss";

const Footer = () => {
    const isPolish = useCheckIfLangIsPolish();
    const fullYear = new Date().getFullYear();
    return (
        <footer className={styles.footer}>
            <div className='container'>
                {/* <div className={styles.row}>
                    <div className={styles.col}>
                       
                    </div>
                </div> */}
                <div className={styles.row}>
                    <div className={styles.col}>
                        {/* <Image
                            src='/logo-mono.png'
                            alt='Poland by Locals Logo'
                            width={112 / 1.5}
                            height={40 / 1.5}
                            className={styles.logo}
                        /> */}
                        <p>{isPolish ? "POMOC" : "HELP"}</p>
                        <Link
                            href={
                                isPolish
                                    ? "/pl/regulamin"
                                    : "/terms-and-conditions-of-use"
                            }
                        >
                            {isPolish
                                ? "Regulamin"
                                : "Terms and conditions of use"}
                        </Link>
                        <Link
                            href={
                                isPolish
                                    ? "/pl/polityka-prywatnosci"
                                    : "/privacy-policy"
                            }
                        >
                            {" "}
                            {isPolish
                                ? "Polityka prywatności"
                                : "Pricacy policy"}
                        </Link>
                        <Link href={isPolish ? "/pl/faq" : "/faq"}>
                            {isPolish
                                ? "Często zadawane pytania"
                                : "Frequenty asked questions"}
                        </Link>
                    </div>

                    <div className={styles.col}>
                        <p> {isPolish ? "O NAS" : "COMPANY"}</p>
                        <Link href={isPolish ? "/pl/o-nas" : "/about-us"}>
                            {isPolish ? "O nas" : "About us"}
                        </Link>
                        <Link
                            href={
                                isPolish
                                    ? "/pl/potrzebujesz-przewodnika"
                                    : "/need-a-guide"
                            }
                        >
                            {isPolish
                                ? "Potrzebujesz przewodnika?"
                                : "Need a guide?"}
                        </Link>
                        <Link
                            href={
                                isPolish
                                    ? "/pl/zostan-localsem"
                                    : "/became-a-local"
                            }
                        >
                            {" "}
                            {isPolish ? "Zostań Localsem" : "Became a Local"}
                        </Link>
                        <Link href={isPolish ? "/pl/kontakt" : "/contact"}>
                            {" "}
                            {isPolish ? "Kontakt" : "Contact"}
                        </Link>
                    </div>
                    <div className={styles.col}>
                        <p>{isPolish ? "CO ROBIMY" : "WHAT WE DO"}</p>
                        <Link href={isPolish ? "/pl/wycieczki" : "/tours"}>
                            {isPolish ? "Wycieczki" : "Tours"}
                        </Link>
                        <Link href={isPolish ? "/pl/eventy" : "/events"}>
                            {isPolish ? "Eventy" : "Events"}
                        </Link>
                        <Link href={isPolish ? "/pl/blog" : "/blog"}>Blog</Link>
                    </div>
                    <div className={styles.col}>
                        <div>
                            <div
                                id='TA_selfserveprop574'
                                className='TA_selfserveprop'
                            >
                                <ul
                                    id='Yr4148Gv'
                                    className='TA_links qijHZZmzNUik'
                                >
                                    <li id='ocPPggC' className='GFpRvY2N'>
                                        <a
                                            target='_blank'
                                            href='https://www.tripadvisor.co.uk/'
                                        >
                                            <img
                                                src='https://www.tripadvisor.co.uk/img/cdsi/img2/branding/150_logo-11900-2.png'
                                                alt='TripAdvisor'
                                            />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <Script
                                async
                                src='https://www.jscache.com/wejs?wtype=selfserveprop&amp;uniq=574&amp;locationId=12644281&amp;lang=en_UK&amp;rating=true&amp;nreviews=3&amp;writereviewlink=true&amp;popIdx=true&amp;iswide=false&amp;border=true&amp;display_version=2'
                                data-loadtrk
                                onLoad={() => {
                                    "this.loadtrk=true";
                                }}
                                strategy='beforeInteractive'
                            ></Script>
                        </div>
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.col} style={{ width: "100%" }}>
                        <span className={styles.copyright}>
                            © {fullYear} Poland by Locals.{" "}
                            {isPolish
                                ? "Wszystkie prawa zastrzeżone"
                                : "All rights reserverd."}
                        </span>
                    </div>
                    <div className={styles.crafty}>
                        <span className={styles.crafty}>
                            Designed by{" "}
                            <a href='https://tomowoj.com'>CRAFTY.STD</a>
                        </span>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
