import React, { useState, useEffect } from "react";
import Image from "next/image";
import styles from "./Header.module.scss";

import { useRouter } from "next/router";

import Link from "next/link";

import useCheckMobileScreen from "../../lib/IsMobile";

// import Button from '@mui/material/Button';

import { useMenuContext } from "../../context/menuContext";

import useCheckIfLangIsPolish from "../../lib/IsPolish";

import { FiPhoneCall, FiMail, FiInstagram, FiMenu, FiX } from "react-icons/fi";

import LangSelector from "./Lang";

const Header = () => {
    const { isMenuOpened, openMenu, closeMenu } = useMenuContext();
    const isPolish = useCheckIfLangIsPolish();
    const router = useRouter();

    const menuItems = isPolish
        ? ["Wycieczki", "Eventy", "Blog", "O Nas", "Kontakt"]
        : ["Tours", "Events", "Blog", "About Us", "Contact"];
    const renderMenuItems = menuItems.map((item, index) => (
        <li key={index}>
            <a
                href={
                    isPolish
                        ? `/pl/${item.replace(/\s+/g, "-").toLocaleLowerCase()}`
                        : `/${item.replace(/\s+/g, "-").toLocaleLowerCase()}`
                }
            >
                {item}
            </a>
        </li>
    ));

    const isMobile = useCheckMobileScreen();
    useEffect(() => {
        router.events.on("routeChangeEnd", (url, { shallow }) => {
            isMobile && closeMenu;
        });
    }, [router]);

    useEffect(() => {
        !isMobile ? openMenu() : closeMenu(false);
    }, [isMobile]);

    return (
        <>
            {!isMobile && (
                <div className={styles.topBar}>
                    <ul>
                        <li>
                            <a href='tel:0048537168775 '>
                                <FiPhoneCall className={styles.icon} />
                                0048 537 168 775{" "}
                            </a>
                        </li>
                        <li>
                            <a href='mailto: contact@polandbylocals.com '>
                                <FiMail className={styles.icon} />
                                contact@polandbylocals.com
                            </a>
                        </li>
                        <li>
                            <a href='http://www.instagram.com/polandbylocals'>
                                <FiInstagram className={styles.icon} />
                                @polandbylocals
                            </a>
                        </li>
                    </ul>
                </div>
            )}
            <header className={styles.header}>
                <div className={styles.logo}>
                    <Link href={isPolish ? "/pl/" : "/"}>
                        <span className={styles}>
                            <Image
                                src='/logo.png'
                                alt='Poland by Locals Logo'
                                width={!isMobile ? 112 : 112 / 1}
                                height={!isMobile ? 40 : 40 / 1}
                            />
                        </span>
                    </Link>
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2rem",
                    }}
                >
                    {isMenuOpened ? (
                        <nav>
                            <ul className={styles.menu}>{renderMenuItems}</ul>
                            <div>
                                <LangSelector />
                                {isMobile ? (
                                    <ul className={styles.contactInfoMobile}>
                                        <li>
                                            <a href='tel:0048537168775'>
                                                <FiPhoneCall
                                                    className={styles.icon}
                                                />
                                                0048 537 168 775{" "}
                                            </a>
                                        </li>
                                        <li>
                                            <a href='mailto: contact@polandbylocals.com '>
                                                <FiMail
                                                    className={styles.icon}
                                                />
                                                contact@polandbylocals.com
                                            </a>
                                        </li>
                                        <li>
                                            <a href='http://www.instagram.com/polandbylocals'>
                                                <FiInstagram
                                                    className={styles.icon}
                                                />
                                                @polandbylocals
                                            </a>
                                        </li>
                                    </ul>
                                ) : null}
                            </div>
                        </nav>
                    ) : null}
                    {isMobile ? (
                        <a
                            className={styles.burgerButton}
                            onClick={isMenuOpened ? closeMenu : openMenu}
                        >
                            {!isMenuOpened ? (
                                <FiMenu className={styles.hamburger} />
                            ) : (
                                <FiX className={styles.hamburger} />
                            )}
                        </a>
                    ) : null}
                </div>
            </header>
        </>
    );
};

export default Header;
