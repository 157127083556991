import "../styles/globals.scss";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

// import { motion, AnimatePresence } from "framer-motion";
import { useRouter } from "next/router";
import { DefaultSeo } from "next-seo";

import useCheckIfLangIsPolish from "../lib/IsPolish";

import { MenuProvider } from "../context/menuContext";
import { SettingsProvider } from "../context/settingsContext";

import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

import ogImg from "../public/og-img.jpg";

function MyApp({ Component, pageProps }) {
    const router = useRouter();
    const canonicalUrl = (
        `https://polandbylocals.com` +
        (router.asPath === "/" ? "" : router.asPath)
    ).split("?")[0];

    const isPolish = useCheckIfLangIsPolish();

    // const { asPath } = useRouter();
    // const variants = {
    //     in: {
    //         opacity: 1,
    //         y: 0,
    //         transition: {
    //             duration: 0.3,
    //             delay: 0,
    //         },
    //     },
    //     out: {
    //         opacity: 0,
    //         y: 5,
    //         transition: {
    //             duration: 0.2,
    //         },
    //     },
    //     initial: {
    //         opacity: 1,
    //         y: 0,
    //         transition: {
    //             duration: 0,
    //         },
    //     },
    // };

    return (
        <>
            <DefaultSeo
                title='Poland by Locals'
                description='We believe that every trip should be a trip of your lifetime. When we travel, we enjoy meeting local people, visiting unique places and tasting regional cusine.'
                canonical={canonicalUrl}
                additionalMetaTags={[
                    {
                        property: "keywords",
                        content:
                            "tours, gdańsk, wycieczki, gdansk, trójmiasto, trojmiasto, tour, guide",
                    },
                ]}
                openGraph={{
                    type: "website",
                    locale: isPolish ? "pl_PL" : "en_EN",
                    url: "https://polandbylocals.com/",
                    siteName: "Poland by Locals",
                    images: [
                        {
                            url: { ogImg },
                            width: 800,
                            height: 600,
                            alt: "Poland by Locals",
                        },
                    ],
                }}
            />
            <MenuProvider>
                <Header />
                {/* <AnimatePresence
                initial={false}
                // mode='wait'
                onExitComplete={() => {
                    if (typeof window !== "undefined") {
                        window.scrollTo({ top: 0 });
                    }
                }}
            >
                <motion.main
                    variants={variants}
                    animate='in'
                    initial='out'
                    exit='out'
                    key={asPath}
                    transition={{ ease: [0.17, 0.67, 0.83, 0.67] }}
                > */}
                <GoogleReCaptchaProvider
                    reCaptchaKey='6LcbN2IkAAAAAEQ2sY4PmHSlgHKoxYizUAs8m87a'
                    scriptProps={{
                        async: false, // optional, default to false,
                        defer: true, // optional, default to false
                        appendTo: "body", // optional, default to "head", can be "head" or "body",
                        nonce: undefined,
                    }}
                >
                    <Component {...pageProps} />
                </GoogleReCaptchaProvider>
                {/* </motion.main>
            </AnimatePresence> */}
                <Footer />
            </MenuProvider>
        </>
    );
}

export default MyApp;
