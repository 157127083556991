import React, { useContext, useState } from "react";
import { client } from "../lib/Client";

const SettingsContext = React.createContext();

// const settingQuery = [
//     `*[!(_id in path('drafts.**')) && _type == "settings" ] {
//         _id,
//         areDatesLocked,
//         endDate,
//         startDate
//     }`,
// ];

// const settings = await client.fetch(settingQuery);

const SettingsProvider = ({ children }) => {
    const [areDatesLocked, setDatesLocked] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    // useEffect(() => {
    //     first;

    //     return () => {};
    // }, []);

    return (
        <SettingsProvider.Provider
            value={{
                areDatesLocked,
                startDate,
                endDate,
            }}
        >
            {children}
        </SettingsProvider.Provider>
    );
};

export const useSettingsContext = () => {
    return useContext(SettingsContext);
};

export { SettingsProvider };
